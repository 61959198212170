import React, { Component } from "react";
import {
  closeLoading,
  errorTips,
  getCurrentCountry,
  openConfirmDialog,
  openLoading,
  successTips,
} from "../../../utils";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Styles } from "./styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "../../../i18n/moment";
import { send_eye_test_survey_email } from "../../BackendApi";
import { withStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

const GreenSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#fff",
    },
    "&$checked + $track": {
      backgroundColor: "#95c11f",
    },
  },
  checked: {},
  track: {},
})(Switch);

const InfoItem = ({ label, text }) => (
  <Typography>
    <strong>{label}</strong> : {text}
  </Typography>
);

class AppDetailDialog extends Component {
  state = {
    isSubmit: false,
    event: {
      id: 0,
      firstName: "first",
      lastName: "last",
      email: "",
      phone: "",
      campaignCode: "",
      description: "",
    },
    errors: {
      noBuyErrorTips: "",
      cancelErrorTips: "",
      descriptionErrorTips: "",
      showCampaignCodeErrorTips: "",
      DateErrorTips: "",
      TimeErrorTips: "",
      WeekErrorTips: "",
    },
    showMore: false,
    showCancelForm: false,
    showNoBuyForm: false,
    showDescriptionForm: false,
    showCampaignCodeForm: false,
    cancelReason: "",
    noBuyOptions: "",
    noBuyReason: "",
    descriptionReason: "",
    campaignCodeReason: "",
    offervoucher: "",
    offerdescription: "",
    close_event_title: "",
    close_event_start_date: "",
    close_event_end_date: "",
    close_event_start_time: "",
    close_event_end_time: "",
    close_event_mo: false,
    close_event_tu: false,
    close_event_we: false,
    close_event_th: false,
    close_event_fr: false,
    close_event_sa: false,
    close_event_su: false,
    close_event_state: false,
    close_event_is_new: false,
    close_event_remark: "",
  };

  constructor(props) {
    super(props);
    const {
      event_offer,
      event_description,
      event_offervoucher,
      event_offerdescription,
      event_name,
      event_start_date,
      event_end_date,
      event_start_time,
      event_end_time,
      event_mo,
      event_tu,
      event_we,
      event_th,
      event_fr,
      event_sa,
      event_su,
      event_is_new,
      event_remark,
    } = this.props;
    this.state.descriptionReason = event_description;
    this.state.campaignCodeReason = event_offer;
    this.state.offervoucher = event_offervoucher;
    this.state.offerdescription = event_offerdescription;
    this.state.close_event_title = event_name;
    this.state.close_event_start_date = event_start_date;
    this.state.close_event_end_date = event_end_date;
    this.state.close_event_start_time = event_start_time;
    this.state.close_event_end_time = event_end_time;
    this.state.close_event_mo = event_mo;
    this.state.close_event_tu = event_tu;
    this.state.close_event_we = event_we;
    this.state.close_event_th = event_th;
    this.state.close_event_fr = event_fr;
    this.state.close_event_sa = event_sa;
    this.state.close_event_su = event_su;
    this.state.close_event_is_new = event_is_new;
    this.state.close_event_remark = event_remark;
    
  }

  openCancelConfirm = () => {
    const { t } = this.props;
    if (!this.state.cancelReason) {
      let errors = {};
      errors = {
        ...errors,
        cancelErrorTips: t("FlexCalendar:tips.cancelErrorTips"),
      };
      this.setState({ errors: errors }, () => {});
    } else {
      openConfirmDialog(
        t("FlexCalendar:title.confirmCancelApp"),
        this.cancel,
        t("FlexCalendar:message.confirmCancelAppDescription"),
        t("FlexCalendar:button.yesDeleteAppointment"),
        t("FlexCalendar:button.noKeepAppointment")
      );
    }
  };
  cancel = () => {
    const { onNoBuyShowDelClick, operation_app_id, authUser } = this.props;
    this.setState(
      {
        showCancelForm: false,
      },
      () => {
        onNoBuyShowDelClick({
          event_id: operation_app_id,
          operation_type: "cancel",
          operating_id: authUser.id,
          description: this.state.cancelReason,
        });
      }
    );
  };

  openMoveConfirm = () => {
    const { t } = this.props;
    openConfirmDialog(
      t("FlexCalendar:title.confirmMoveApp"),
      this.props.onMove,
      t("FlexCalendar:message.confirmMoveAppDescription"),
      t("FlexCalendar:button.yesMoveAppointment"),
      t("FlexCalendar:button.noKeepAppointment")
    );
  };

  openNoShowConfirm = () => {
    const { t } = this.props;
    openConfirmDialog(
      t("FlexCalendar:title.confirmNoShowApp"),
      this.noshow,
      t("FlexCalendar:message.confirmNoShowAppDescription"),
      t("FlexCalendar:button.yesNoShowAppointment"),
      t("FlexCalendar:button.noKeepAppointment")
    );
  };
  openStoreClosingReminderConfirm = () => {
    const { t } = this.props;
    openConfirmDialog(
      t("FlexCalendar:title.confirmStoreClosingReminderApp"),
      this.storeClosingReminder,
      t("FlexCalendar:message.confirmStoreClosingReminderAppDescription"),
      t("FlexCalendar:button.yesStoreClosingReminder"),
      t("FlexCalendar:button.noKeepAppointment")
    );
  };
  storeClosingReminder = () => {
    const { t, onNoBuyShowDelClick, operation_app_id, authUser } = this.props;
    onNoBuyShowDelClick({
      event_id: operation_app_id,
      operation_type: "reminder",
      operating_id: authUser.id,
      description: "",
    });
  };
  noshow = () => {
    const { t, onNoBuyShowDelClick, operation_app_id, authUser } = this.props;
    onNoBuyShowDelClick({
      event_id: operation_app_id,
      operation_type: "noshow",
      operating_id: authUser.id,
      description: "",
    });
  };

  openNoBuyConfirm = () => {
    const { t } = this.props;
    if (
      this.state.noBuyOptions == t("FlexCalendar:nobuy.other") &&
      this.state.noBuyReason == ""
    ) {
      let errors = {};
      errors = {
        ...errors,
        noBuyErrorTips: t("FlexCalendar:tips.noBuyErrorTips"),
      };
      this.setState({ errors: errors }, () => {
        console.info(this.state.errors.noBuyErrorTips);
      });
    } else {
      openConfirmDialog(
        t("FlexCalendar:title.confirmNoBuyApp"),
        this.nobuy,
        t("FlexCalendar:message.confirmNoBuyAppDescription"),
        t("FlexCalendar:button.yesNoBuyAppointment"),
        t("FlexCalendar:button.noKeepAppointment")
      );
    }
  };
  nobuy = () => {
    const { t, onNoBuyShowDelClick, operation_app_id, authUser } = this.props;
    this.setState(
      {
        showNoBuyForm: false,
      },
      () => {
        onNoBuyShowDelClick({
          event_id: operation_app_id,
          operation_type: "nobuy",
          operating_id: authUser.id,
          description:
            this.state.noBuyOptions == t("FlexCalendar:nobuy.other")
              ? this.state.noBuyReason
              : this.state.noBuyOptions,
        });
      }
    );
  };

  openResendEmailConfirm = () => {
    const { t } = this.props;
    openConfirmDialog(
      t("FlexCalendar:title.confirmResendEmailApp"),
      this.resendemail,
      t("FlexCalendar:message.confirmResendEmailAppDescription"),
      t("FlexCalendar:button.yesResendEmailAppointment"),
      t("FlexCalendar:cancel")
    );
  };
  resendemail = () => {
    const { t, operation_app_id, onNoBuyShowDelClick, authUser } = this.props;
    onNoBuyShowDelClick({
      event_id: operation_app_id,
      operation_type: "resend",
      operating_id: authUser.id,
      description: "",
    });
  };

  openSendEyeTestSurveyEmailConfirm = () => {
    const { t } = this.props;
    openConfirmDialog(
      t("FlexCalendar:Confirm to send the eye test survey email?"),
      this.sendEyeTestSurveyEmail,
      t("FlexCalendar:This is a email for eye test survey."),
      t("FlexCalendar:Yes, send it now"),
      t("FlexCalendar:no, cancel this operation")
    );
  };

  sendEyeTestSurveyEmail = () => {
    const { t, operation_app_id } = this.props;
    openLoading();
    send_eye_test_survey_email({
      appointment_id: operation_app_id,
    })
      .then((result) => {
        closeLoading();
        console.log("Send eye test survey email success:", result);
        successTips(t("FlexCalendar:Send email success"));
      })
      .catch((error) => {
        closeLoading();
        console.log("Send eye test survey email failed:", error);
        errorTips(t("FlexCalendar:send email failed."));
        if (error.code == 500) {
          errorTips(t("message.networkIsBusyPleaseRetry"));
        } else if (error.code == 4101) {
          errorTips(
            t("FlexCalendar:send email failed. No matched appointment")
          );
        }
      });
  };

  openDescriptionConfirm = () => {
    const { t } = this.props;
    if (!this.state.descriptionReason) {
      let errors = {};
      errors = {
        ...errors,
        descriptionErrorTips: t("FlexCalendar:tips.descriptionErrorTips"),
      };
      this.setState({ errors: errors }, () => {});
    } else {
      openConfirmDialog(
        t("FlexCalendar:title.confirmDescriptionApp"),
        this.editdescription,
        t("FlexCalendar:message.confirmDescriptionAppDescription"),
        t("FlexCalendar:button.yesDescriptionAppointment"),
        t("FlexCalendar:button.noKeepAppointment")
      );
    }
  };
  editdescription = () => {
    const { t, operation_app_id, onNoBuyShowDelClick, authUser } = this.props;
    this.setState(
      {
        showDescriptionForm: false,
      },
      () => {
        onNoBuyShowDelClick({
          event_id: operation_app_id,
          operation_type: "u_dp",
          operating_id: authUser.id,
          description: this.state.descriptionReason,
        });
      }
    );
  };

  openCampaignCodeConfirm = () => {
    const { t } = this.props;
    if (!this.state.campaignCodeReason) {
      let errors = {};
      errors = {
        ...errors,
        descriptionErrorTips: t("FlexCalendar:tips.CampaignCodeErrorTips"),
      };
      this.setState({ errors: errors }, () => {});
    } else {
      openConfirmDialog(
        t("FlexCalendar:title.confirmCampaignCodeApp"),
        this.editcampaigncode,
        t("FlexCalendar:message.confirmCampaignCodeAppDescription"),
        t("FlexCalendar:button.yesCampaignCodeAppointment"),
        t("FlexCalendar:button.noKeepAppointment")
      );
    }
  };
  editcampaigncode = () => {
    const { t, operation_app_id, onNoBuyShowDelClick, authUser } = this.props;
    this.setState(
      {
        showCampaignCodeForm: false,
      },
      () => {
        onNoBuyShowDelClick({
          event_id: operation_app_id,
          operation_type: "offer",
          operating_id: authUser.id,
          campaign_code: this.state.campaignCodeReason,
          description: "",
        });
      }
    );
  };

  openLogConfirm = () => {
    const { t, openHistory } = this.props;
    openHistory();
  };

  time_list = () => {
    let state_time = new Date("1999/01/01 07:00:00");
    let end_time = new Date("1999/01/01 22:00:00");
    let all_list = [];
    while (state_time <= end_time) {
      let interval_time = state_time;
      all_list.push(moment(interval_time).format("HH:mm:ss"));
      var min = state_time.getMinutes();
      state_time.setMinutes(min + 15);
    }
    return all_list;
  };

  validEventAttribute = () => {
    const { t } = this.props;
    let errors = {};
    if (
      !this.state.close_event_start_date ||
      !this.state.close_event_end_date ||
      this.state.close_event_start_date > this.state.close_event_end_date
    ) {
      errors = {
        ...errors,
        DateErrorTips: t("FlexCalendar:tips.DateErrorTips"),
      };
    } else if (
      this.state.close_event_start_date === this.state.close_event_end_date &&
      this.state.close_event_start_time >= this.state.close_event_end_time
    ) {
      errors = {
        ...errors,
        TimeErrorTips: t("FlexCalendar:tips.TimeErrorTips"),
      };
    } else if (
      !this.state.close_event_mo &&
      !this.state.close_event_tu &&
      !this.state.close_event_we &&
      !this.state.close_event_th &&
      !this.state.close_event_fr &&
      !this.state.close_event_sa &&
      !this.state.close_event_su
    ) {
      errors = {
        ...errors,
        WeekErrorTips: t("FlexCalendar:tips.WeekErrorTips"),
      };
    }
    this.setState({ errors: errors });
    return Object.keys(errors).length === 0 ? true : false;
  };

  editRetentionTime = () => {
    const { t } = this.props;
    if (this.validEventAttribute()) {
      openConfirmDialog(
        t("FlexCalendar:title.confirmEditRetentionTime"),
        this.EditRetentionTime,
        t("FlexCalendar:message.confirmEditNewRetentionTime"),
        t("FlexCalendar:button.yesEditRetentionTime"),
        t("FlexCalendar:button.noEditRetentionTime")
      );
    }
  };

  EditRetentionTime = () => {
    const { t, onEditRetentionTime, operation_app_id } = this.props;
    let time_zone = moment.tz.guess();
    onEditRetentionTime({
      title: this.state.close_event_title,
      start_date: moment(this.state.close_event_start_date).format(
        "YYYY-MM-DD"
      ),
      end_date: moment(this.state.close_event_end_date).format("YYYY-MM-DD"),
      start_time: this.state.close_event_start_time,
      end_time: this.state.close_event_end_time,
      mo: this.state.close_event_mo,
      tu: this.state.close_event_tu,
      we: this.state.close_event_we,
      th: this.state.close_event_th,
      fr: this.state.close_event_fr,
      sa: this.state.close_event_sa,
      su: this.state.close_event_su,
      event_tz: time_zone,
      id: operation_app_id,
    });
  };
  cancelRetentionTime = () => {
    const { t, onCancelRetentionTime, operation_app_id } = this.props;
    openConfirmDialog(
      t("FlexCalendar:title.confirmCancelRetentionTime"),
      onCancelRetentionTime({ status: false, id: operation_app_id }),
      t("FlexCalendar:message.confirmCancelNewRetentionTime"),
      t("FlexCalendar:button.yesCancelRetentionTime"),
      t("FlexCalendar:button.noCancelRetentionTime")
    );
  };

  render() {
    const {
      t,
      classes,
      open,
      onClose,
      event_name,
      event_phone,
      event_mobile,
      event_email,
      event_is_new,
      event_remark,
      event_not_buy_reason,
      offers,
      event_status,
      event_app_time,
      event_eye_test,
      BusinessHoursList,
      event_start_date,
      authUser,
      internalUser,
      onRefresh,
      event_double_confirm_required,
      event_double_confirm,
      isRefresh
    } = this.props;
    //NORMAL,NOT BUYING,NOT YET,DONE
    let country_code = getCurrentCountry();
    let app_date = moment(event_app_time).format("YYYY-MM-DD");
    let lm = "";
    console.info(event_status, event_app_time);
    console.info(new Date(event_app_time) > new Date());
    if (BusinessHoursList) {
      BusinessHoursList.map((option, index) => {
        if (option.date === app_date) {
          if (index === 0 || index === 1) {
            lm = "lm1";
          } else if (index === 2) {
            lm = "lm2";
          } else if (index === 3) {
            lm = "lm3";
          }
        }
      });
    }
    return (
      <>
        {!event_start_date ? (
          <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
          >
            <DialogTitle id="simple-dialog-title">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" paragraph={true}>
                  {event_name}
                  {!isRefresh?
                    <IconButton
                      aria-label="refresh"
                      className={classes.refreshButton}
                      onClick={onRefresh}
                    >
                      <RefreshIcon />
                    </IconButton>:""
                  }
                </Typography>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={this.state.showMore}
                    onChange={() =>
                      this.setState({ showMore: !this.state.showMore })
                    }
                  />
                }
                label={t("label.showMore")}
              />
              <Box p={3}>
                <Collapse in={this.state.showMore} collapsedSize={140}>
                  {/* <InfoItem
                    label={t("label.customer_info")}
                    text={event_is_new?t("label.already a customer"):t("label.new customer")}
                  /> */}
                  {event_double_confirm_required?<InfoItem
                    label={t("label.double_confirm")}
                    text={event_double_confirm?t("label.Yes"):t("label.No")}
                  />:""
                  }
                  <InfoItem
                    label={t("label.agent remark")}
                    text={event_remark}
                  />

                  <InfoItem
                    label={t("label.date")}
                    text={moment(event_app_time).format("lll")}
                  />
                  <InfoItem
                    label={t("label.phone")}
                    text={event_phone !== "" ? event_phone : event_mobile}
                  />
                  <InfoItem label={t("label.email")} text={event_email} />
                  <InfoItem
                    label={t("label.offer")}
                    text={this.state.campaignCodeReason}
                  />
                  <InfoItem
                    label={t("FlexCalendar:Created by")}
                    text={this.props.event_source}
                  />
                  <InfoItem
                    label={t("label.description")}
                    text={this.state.descriptionReason}
                  />
                  <InfoItem
                    label={t("FlexCalendar:label.offervoucher")}
                    text={this.state.offervoucher}
                  />
                  <InfoItem
                    label={t("FlexCalendar:label.offerdescription")}
                    text={this.state.offerdescription}
                  />
                  {country_code === "gb" ||
                  country_code === "us" ||
                  country_code === "de" ? (
                    <InfoItem
                      label={t("FlexCalendar:label.eyetest")}
                      text={event_eye_test ? "True" : "False"}
                    />
                  ) : null}
                </Collapse>
              </Box>
              {/* {event_status != "NOT BUYING" ? (
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() =>
                  this.setState({
                    showNoBuyForm: !this.state.showNoBuyForm,
                    showCancelForm: false,
                    showDescriptionForm: false,
                    showCampaignCodeForm: false,
                  })
                }
              >
                {t("FlexCalendar:button.noBuy")}
              </Button>
            ) : null} */}
              {/* {event_status != "NOT YET" ? (
              <Button
                variant="outlined"
                className={classes.button}
                onClick={this.openNoShowConfirm}
              >
                {t("FlexCalendar:button.noShow")}
              </Button>
            ) : null} */}
              {event_status === "NORMAL" &&
              event_app_time &&
              new Date(event_app_time) > new Date() ? (
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={this.openMoveConfirm}
                >
                  {t("button.move")}
                </Button>
              ) : null}
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() =>
                  this.setState({
                    showDescriptionForm: !this.state.showDescriptionForm,
                    showNoBuyForm: false,
                    showCancelForm: false,
                    showCampaignCodeForm: false,
                  })
                }
              >
                {t("button.editDescription")}
              </Button>
              {event_status !== "DONE" ? (
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={() =>
                    this.setState({
                      showCampaignCodeForm: !this.state.showCampaignCodeForm,
                      showNoBuyForm: false,
                      showCancelForm: false,
                      showDescriptionForm: false,
                    })
                  }
                >
                  {t("button.editCampaignCode")}
                </Button>
              ) : null}
              {event_status === "NORMAL" &&
              event_app_time &&
              new Date(event_app_time) > new Date() ? (
                <Button
                  className={classes.button + " " + classes.secondary}
                  onClick={() =>
                    this.setState({
                      showCancelForm: !this.state.showCancelForm,
                      showNoBuyForm: false,
                      showDescriptionForm: false,
                      showCampaignCodeForm: false,
                    })
                  }
                >
                  <span>{t("button.delete")}</span>
                </Button>
              ) : null}
              {event_status === "NORMAL" &&
              event_app_time &&
              new Date(event_app_time) > new Date() ? (
                <Button
                  variant="outlined"
                  className={classes.button + " " + classes.secondary}
                  onClick={this.openStoreClosingReminderConfirm}
                >
                  <span>{t("FlexCalendar:button.storeClosingReminder")}</span>
                </Button>
              ) : null}
              {event_status === "NORMAL" &&
              event_app_time &&
              new Date(event_app_time) > new Date() ? (
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={this.openResendEmailConfirm}
                >
                  {t("button.resendEmail")}
                </Button>
              ) : null}
              <Button
                variant="outlined"
                className={classes.button}
                onClick={this.openLogConfirm}
              >
                {t("button.log")}
              </Button>
              {country_code === "us" &&
              event_status === "NORMAL" &&
              event_app_time &&
              new Date(event_app_time) > new Date() ? (
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={this.openSendEyeTestSurveyEmailConfirm}
                >
                  {t("FlexCalendar:Send eye test survey Email")}
                </Button>
              ) : null}
              {this.state.showCancelForm && (
                <>
                  <TextField
                    error={this.state.errors.cancelErrorTips ? true : false}
                    helperText={this.state.errors.cancelErrorTips}
                    id="outlined-cancelReason-input"
                    label={t("FlexCalendar:label.cancelReason")}
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.cancelReason}
                    onChange={(e) =>
                      this.setState({ cancelReason: e.target.value })
                    }
                  />
                  <DialogActions>
                    <Button
                      disabled={this.state.isSubmit}
                      onClick={this.openCancelConfirm}
                      className={classes.buttonGreen}
                    >
                      {t("button.confirm")}
                    </Button>
                  </DialogActions>
                </>
              )}
              {this.state.showNoBuyForm && (
                <div>
                  <FormControl
                    component="fieldset"
                    style={{ width: "-webkit-fill-available" }}
                  >
                    <FormLabel
                      component="legend"
                      style={{ textAlign: "center" }}
                    >
                      Nobuy
                    </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={
                        this.state.noBuyOptions != ""
                          ? this.state.noBuyOptions
                          : t("FlexCalendar:nobuy.noShowUp")
                      }
                      onChange={(e) =>
                        this.setState({ noBuyOptions: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value={t("FlexCalendar:nobuy.noShowUp")}
                        control={<Radio />}
                        label={t("FlexCalendar:nobuy.noShowUp")}
                      />
                      <FormControlLabel
                        value={t("FlexCalendar:nobuy.anotherapp")}
                        control={<Radio />}
                        label={t("FlexCalendar:nobuy.anotherapp")}
                      />
                      <FormControlLabel
                        value={t("FlexCalendar:nobuy.other")}
                        control={<Radio />}
                        label={t("FlexCalendar:nobuy.other")}
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    error={this.state.errors.noBuyErrorTips ? true : false}
                    helperText={this.state.errors.noBuyErrorTips}
                    id="outlined-nobuyReason-input"
                    label={t("FlexCalendar:label.noBuyReason")}
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.noBuyReason}
                    onChange={(e) =>
                      this.setState({ noBuyReason: e.target.value })
                    }
                  />
                  <DialogActions>
                    <Button
                      disabled={this.state.isSubmit}
                      onClick={this.openNoBuyConfirm}
                      className={classes.buttonGreen}
                    >
                      {t("button.confirm")}
                    </Button>
                  </DialogActions>
                </div>
              )}
              {this.state.showDescriptionForm && (
                <div>
                  <TextField
                    error={
                      this.state.errors.showDescriptionErrorTips ? true : false
                    }
                    helperText={this.state.errors.showDescriptionErrorTips}
                    id="outlined-description-input"
                    label={t("FlexCalendar:label.description")}
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.descriptionReason}
                    onChange={(e) =>
                      this.setState({ descriptionReason: e.target.value })
                    }
                  />
                  <DialogActions>
                    <Button
                      disabled={this.state.isSubmit}
                      onClick={this.openDescriptionConfirm}
                      className={classes.buttonGreen}
                    >
                      {t("button.confirm")}
                    </Button>
                  </DialogActions>
                </div>
              )}
              {this.state.showCampaignCodeForm && (
                <div>
                  <TextField
                    id="outlined-select-offer-native"
                    select
                    required
                    error={
                      this.state.errors.showCampaignCodeErrorTips ? true : false
                    }
                    helperText={this.state.errors.showCampaignCodeErrorTips}
                    label={t("label.offer")}
                    fullWidth
                    name="offer"
                    margin="normal"
                    variant="outlined"
                    value={this.state.campaignCodeReason}
                    onChange={(e) => {
                      this.setState(
                        { campaignCodeReason: e.target.value },
                        () => {
                          offers.map((option) => {
                            if (
                              option.campaign_code == this.state.campaign_code
                            ) {
                              this.setState({
                                offervoucher: option.campaign_voucher,
                                offerdescription: option.campaign_title,
                              });
                            }
                          });
                        }
                      );
                    }}
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    {offers.map((option) => {
                      console.info("lm", lm);
                      let CCode = option.campaign_code;
                      if (CCode.indexOf("lm") != -1) {
                        if (lm && CCode.indexOf(lm) != -1) {
                          return (
                            <MenuItem
                              key={option.campaign_code}
                              value={option.campaign_code}
                            >
                              {option.campaign_name}
                            </MenuItem>
                          );
                        } else if (
                          !lm &&
                          CCode.indexOf("lm1") == -1 &&
                          CCode.indexOf("lm2") == -1 &&
                          CCode.indexOf("lm3") == -1
                        ) {
                          return (
                            <MenuItem
                              key={option.campaign_code}
                              value={option.campaign_code}
                            >
                              {option.campaign_name}
                            </MenuItem>
                          );
                        }
                      } else {
                        return (
                          <MenuItem
                            key={option.campaign_code}
                            value={option.campaign_code}
                          >
                            {option.campaign_name}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextField>
                  <DialogActions>
                    <Button
                      disabled={this.state.isSubmit}
                      onClick={this.openCampaignCodeConfirm}
                      className={classes.buttonGreen}
                    >
                      {t("button.confirm")}
                    </Button>
                  </DialogActions>
                </div>
              )}
            </DialogContent>
          </Dialog>
        ) : internalUser ? (
          <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
          >
            <DialogTitle id="simple-dialog-title">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" paragraph={true}>
                  {t("FlexCalendar:RetentionTimeEditOrCancel")}
                </Typography>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <Grid container justifyContent="space-between">
                <TextField
                  size="small"
                  ref="title"
                  id="title"
                  label={t("FlexCalendar:RetentionTitle")}
                  variant="outlined"
                  color="secondary"
                  style={{ margin: "1.5ch", width: "100%" }}
                  value={this.state.close_event_title}
                  onChange={(e) => {
                    this.setState({
                      close_event_title: e.target.value || "",
                    });
                  }}
                />
              </Grid>
              <Grid container justifyContent="space-between">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    margin="normal"
                    variant="outlined"
                    label={t("FlexCalendar:RetentionStartDate")}
                    id="date-picker-dialog"
                    views={["year", "month", "day"]}
                    value={moment(this.state.close_event_start_date).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(e) => {
                      this.setState({
                        close_event_start_date: e,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        style={{ margin: "0.5ch 1.5ch", width: "42%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    margin="normal"
                    variant="outlined"
                    label={t("FlexCalendar:RetentionEndDate")}
                    id="date-picker-dialog"
                    views={["year", "month", "day"]}
                    value={moment(this.state.close_event_end_date).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(e) => {
                      this.setState({
                        close_event_end_date: e,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        style={{ margin: "0.5ch 1.5ch", width: "42%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid container justifyContent="space-between">
                <FormControl
                  fullWidth
                  required
                  style={{
                    margin: "1.5ch",
                    width: "42%",
                    display: "inline-block",
                  }}
                  variant="outlined"
                  size="small"
                  margin="normal"
                >
                  <InputLabel id="lead-select-offer-label">
                    {t("FlexCalendar:RetentionStartTime")}
                  </InputLabel>
                  <Select
                    labelId="lead-select-offer-label"
                    id="lead-select-offer"
                    value={this.state.close_event_start_time}
                    label={t("FlexCalendar:RetentionStartTime")}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      this.setState({
                        close_event_start_time: e.target.value || "",
                      });
                    }}
                  >
                    {this.time_list().map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  required
                  style={{
                    margin: "1.5ch",
                    width: "42%",
                    display: "inline-block",
                  }}
                  variant="outlined"
                  size="small"
                  margin="normal"
                >
                  <InputLabel id="lead-select-offer-label">
                    {t("FlexCalendar:RetentionEndTime")}
                  </InputLabel>
                  <Select
                    labelId="lead-select-offer-label"
                    id="lead-select-offer"
                    value={this.state.close_event_end_time}
                    label={t("FlexCalendar:RetentionEndTime")}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      this.setState({
                        close_event_end_time: e.target.value || "",
                      });
                    }}
                  >
                    {this.time_list().map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-between">
                <FormGroup
                  style={{
                    margin: "1.5ch",
                    width: "100%",
                    display: "-webkit-box",
                  }}
                >
                  <Grid style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.close_event_mo}
                          onChange={(e) => {
                            this.setState({
                              close_event_mo: !this.state.close_event_mo,
                            });
                          }}
                        />
                      }
                      label={t("FlexCalendar:RetentionMon")}
                    />
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.close_event_tu}
                          onChange={(e) => {
                            this.setState({
                              close_event_tu: !this.state.close_event_tu,
                            });
                          }}
                        />
                      }
                      label={t("FlexCalendar:RetentionTue")}
                    />
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.close_event_we}
                          onChange={(e) => {
                            this.setState({
                              close_event_we: !this.state.close_event_we,
                            });
                          }}
                        />
                      }
                      label={t("FlexCalendar:RetentionWed")}
                    />
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.close_event_th}
                          onChange={(e) => {
                            this.setState({
                              close_event_th: !this.state.close_event_th,
                            });
                          }}
                        />
                      }
                      label={t("FlexCalendar:RetentionThu")}
                    />
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.close_event_fr}
                          onChange={(e) => {
                            this.setState({
                              close_event_fr: !this.state.close_event_fr,
                            });
                          }}
                        />
                      }
                      label={t("FlexCalendar:RetentionFri")}
                    />
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.close_event_sa}
                          onChange={(e) => {
                            this.setState({
                              close_event_sa: !this.state.close_event_sa,
                            });
                          }}
                        />
                      }
                      label={t("FlexCalendar:RetentionSai")}
                    />
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.close_event_su}
                          onChange={(e) => {
                            this.setState({
                              close_event_su: !this.state.close_event_su,
                            });
                          }}
                        />
                      }
                      label={t("FlexCalendar:RetentionSun")}
                    />
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid container justifyContent="space-between">
                <Button
                  variant="outlined"
                  className={classes.button}
                  style={{ backgroundColor: "#94c11e" }}
                  onClick={this.editRetentionTime}
                >
                  <span style={{ color: "#fff" }}>
                    {t("button.EditRetentionTimeConfirm")}
                  </span>
                </Button>
                <Button
                  className={classes.button + " " + classes.secondary}
                  onClick={this.cancelRetentionTime}
                >
                  <span style={{ color: "#fff" }}>
                    {t("button.CancelRetentionTime")}
                  </span>
                </Button>
              </Grid>
            </DialogContent>
          </Dialog>
        ) : null}
      </>
    );
  }
}

export default withStyles(Styles)(withTranslation()(AppDetailDialog));
